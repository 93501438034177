import React, { useState } from 'react';
import axios from 'axios';

const usersCrypts = () => {
  const [uniqueUserId, setUniqueUserId] = useState('');
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);

  const handleCheck = async () => {
    if (!uniqueUserId) {
      alert('Please enter your Unique User ID!');
      return;
    }

    try {
      const response = await axios.get(`https://cryptools.info/api/user-data/${uniqueUserId}`);
      setUserData(response.data);

      setError(null);
    } catch (error) {
      setUserData(null);
      setError('Invalid UUID. Please try again.');
    }
  };

  const closeErrorPopup = () => {
    setError(null);
  };

  return (



    <div className="min-h-screen flex items-start sm:items-center justify-center bg-background pt-8 sm:pt-0">
      <div className="w-full max-w-2xl p-4 sm:p-8 bg-background/50 backdrop-blur-lg rounded-2xl shadow-xl border border-accent/20 mx-4 sm:mx-0">
        <h2 className="text-2xl sm:text-3xl font-bold mb-4 sm:mb-6 text-text bg-gradient-to-r from-accent to-primary bg-clip-text text-transparent">
          Authenticate with your UUID
        </h2>

        <div className="mb-6 sm:mb-8 flex flex-col sm:flex-row gap-3 sm:gap-4">
          <input
            type="text"
            placeholder="Enter your UUID"
            value={uniqueUserId}
            onChange={(e) => setUniqueUserId(e.target.value)}

            className="flex-1 p-3 sm:p-4 border border-accent/20 rounded-xl bg-background/70 focus:ring-2 focus:ring-accent focus:outline-none transition-all duration-300"
          />
          <button 
            onClick={handleCheck} 

            className="px-4 sm:px-6 py-3 sm:py-4 bg-accent hover:bg-accent/90 text-white rounded-xl transition-all duration-300 transform hover:scale-105 shadow-lg"
          >
            Login
          </button>
        </div>

        {error && (


          <div className="fixed inset-0 flex items-center justify-center bg-black/70 backdrop-blur-sm z-50 px-4">
            <div className="p-6 sm:p-8 bg-background/95 rounded-2xl shadow-2xl border-4 border-red-500/50 text-center w-full sm:w-2/3 md:w-1/2 transform transition-all duration-300 animate-fadeIn hover:scale-[1.02]">
              <div className="mb-4">

                <svg className="w-16 sm:w-20 h-16 sm:h-20 mx-auto text-red-500 animate-bounce" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                </svg>
              </div>


              <h3 className="text-2xl sm:text-3xl font-bold text-red-500 mb-3 sm:mb-4 bg-gradient-to-r from-red-500 to-red-600 bg-clip-text text-transparent">Error Detected</h3>
              <p className="text-text text-lg sm:text-xl mb-4 sm:mb-6 font-bold">{error}</p>
              <button 
                onClick={closeErrorPopup} 

                className="px-6 sm:px-8 py-3 sm:py-4 bg-gradient-to-r from-red-500 to-red-600 text-white rounded-xl transition-all duration-300 hover:scale-105 hover:shadow-lg hover:from-red-600 hover:to-red-700 font-bold text-base sm:text-lg"
              >
                Got it
              </button>
            </div>
          </div>
        )}

        {userData && (



          <div className="space-y-4 sm:space-y-6 animate-fadeIn">
            <div className="p-3 sm:p-4 bg-primary/10 rounded-xl">
              <h3 className="text-xl sm:text-2xl font-semibold text-text">
                Credits Remaining: <span className="text-accent">{userData.credits}</span>
              </h3>
            </div>
            <div>


              <h3 className="text-lg sm:text-xl font-semibold mb-3 sm:mb-4 text-text">Previous Builds</h3>
              <div className="space-y-3 sm:space-y-4">
                {userData.builds.map((build, index) => (
                  <div 
                    key={index} 

                    className="flex flex-col sm:flex-row items-start sm:items-center justify-between p-3 sm:p-4 bg-background/70 rounded-xl border border-accent/20 hover:border-accent/40 transition-all duration-300"
                  >




                    <div className="flex flex-col mb-2 sm:mb-0">
                      <span className="text-xs sm:text-sm text-text/70">{build.date}</span>
                      <span className="text-sm sm:text-base text-text font-medium">{build.filename}</span>
                    </div>
                    <a
                      href={build.download_url}
                      download
                      target="_blank"
                      rel="noopener noreferrer"

                      className="px-3 sm:px-4 py-2 bg-primary hover:bg-primary/90 text-white rounded-lg transition-all duration-300 transform hover:scale-105 shadow-md flex items-center gap-2 text-sm sm:text-base"
                    >

                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 sm:h-5 sm:w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                      </svg>
                      Download
                    </a>

                  </div>

                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default usersCrypts;