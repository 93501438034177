// src/components/Sidebar.jsx
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Sidebar = ({ isMobileMenuOpen, toggleMobileMenu }) => {
  const location = useLocation();

  //FREE TOOLS
  const freeFeatureLinks = [
    {
      to: "/WalletGenerator",
      icon: (
        <path 
          strokeLinecap="round" 
          strokeLinejoin="round" 
          strokeWidth={2} 
          d="M4 4c0-1.1.9-2 2-2h12a2 2 0 012 2v16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm3 1v1h10V5H7zm0 3v1h10V8H7zm0 3v1h10v-1H7zm0 3v1h10v-1H7zm0 3v1h10v-1H7z"
        />      ),
      label: "Wallet Generator"
    },
    {
      to: "/MultipleWalletGenerator",
      icon: (
        <path 
          strokeLinecap="round" 
          strokeLinejoin="round" 
          strokeWidth={2} 
          d="M3 10h18M7 15h1m4 0h1m4 0h1M3 10h18M3 10a2 2 0 012-2h14a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6z"
        />      ),
      label: "Multi-Wallet Generator"
    },
    {
      to: "/swapExchange",
      icon: (
        <path 
          strokeLinecap="round" 
          strokeLinejoin="round" 
          strokeWidth={2} 
          d="M8 7h12m0 0l-4-4m4 4l-4 4m-4 6H4m0 0l4 4m-4-4l4-4"
        />      ),
      label: "Crypto Swap"
    },
    {
      to: "/balanceChecker",
      icon: (
        <path 
          strokeLinecap="round" 
          strokeLinejoin="round" 
          strokeWidth={2} 
          d="M8 7h12m0 0l-4-4m4 4l-4 4m-4 6H4m0 0l4 4m-4-4l4-4"
        />      ),
      label: "Balance Checker"
    },
    {
      to: "/mnemonicDerivator",
      icon: (
        <path 
          strokeLinecap="round" 
          strokeLinejoin="round" 
          strokeWidth={2} 
          d="M9 12h6m-6 4h6m-6-8h6m-5-6h4a2 2 0 012 2v14a2 2 0 01-2 2H9a2 2 0 01-2-2V6a2 2 0 012-2z"
        />      ),
      label: "Mnemonic Derivator"
    },
  ];

    //PAID TOOLS
  const paidFeatureLinks = [

    {
      to: "/premiumOne",
      icon: (
        <path 
          strokeLinecap="round" 
          strokeLinejoin="round" 
          strokeWidth={2} 
          d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" 
        />
      ),
      label: "Coming Soon"
    },
  ];


  const renderNavigationLink = ({ to, icon, label }) => {
    const isActive = location.pathname === to;
    return (
      <Link
        key={to}
        to={to}
        className={`w-full px-4 py-3 rounded-lg flex items-center gap-3 transition-all duration-200 group ${
          isActive 
            ? 'bg-accent/10 text-accent' 
            : 'text-text hover:bg-accent/10 hover:text-accent'
        }`}
        onClick={toggleMobileMenu}
      >
        <svg className="w-5 h-5 group-hover:scale-110 transition-transform" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          {icon}
        </svg>
        <span className="font-medium">{label}</span>
      </Link>
    );
  };

  return (
    <div className="relative">
      {/* Mobile toggle button */}
      <button
        className="lg:hidden fixed top-4 left-4 z-40 p-3 bg-secondary rounded-lg text-accent hover:text-primary transition-colors duration-200 focus:outline-none"
        onClick={toggleMobileMenu}
      >
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          {isMobileMenuOpen ? (
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          ) : (
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
          )}
        </svg>
      </button>

      {/* Sidebar menu */}
      <div
        className={`fixed inset-0 z-30 bg-secondary/95 backdrop-blur-sm flex flex-col lg:relative lg:inset-auto lg:w-72 lg:h-screen lg:shadow-lg lg:sticky lg:top-0 ${
          isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'
        } transition-all duration-300 ease-in-out`}
      >
        <div className="flex-1 overflow-y-auto">
          <div className="p-6">
            <div className="flex flex-col items-center gap-3 mb-8 mt-14 lg:mt-0">
              <h1 className="text-3xl font-bold text-accent bg-gradient-to-r from-accent to-primary bg-clip-text text-transparent">
                CrypTools.info
              </h1>
            </div>

            <div className="h-4"></div>

            <div>
              <h3 className="text-xl font-bold mb-4 text-accent bg-gradient-to-r from-purple-500 to-blue-500 bg-clip-text text-transparent">
                Free Tools
              </h3>
            </div>
            
            <nav className="space-y-2">
              {freeFeatureLinks.map(renderNavigationLink)}
            </nav>

            <div className="mt-8">
              <h3 className="text-xl font-bold mb-4 text-accent bg-gradient-to-r from-purple-500 to-blue-500 bg-clip-text text-transparent">
                Paid Tools
              </h3>
              <nav className="space-y-2">
                {paidFeatureLinks.map(renderNavigationLink)}
              </nav>
            </div>

            <div className="mt-8">
              <h3 className="text-xl font-bold mb-4 text-accent bg-gradient-to-r from-purple-500 to-blue-500 bg-clip-text text-transparent">
                Others
              </h3>
              <nav className="space-y-2">
                {[
                  {
                    to: "/WalletRecovery",
                    icon: (
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                      />
                    ),
                    label: "Wallet Recovery"
                  },
                ].map(renderNavigationLink)}
              </nav>
            </div>
          </div>
        </div>

        <div className="flex-shrink-0 p-6 border-t border-accent/10">
          <Link
            to="/profile"
            className="w-full px-4 py-3 rounded-lg flex items-center gap-3 transition-all duration-200 group text-text hover:bg-accent/10 hover:text-accent mb-4"
            onClick={toggleMobileMenu}
          >
            <svg className="w-5 h-5 group-hover:scale-110 transition-transform" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7ZM4 21C4 17.134 7.13401 14 11 14H13C16.866 14 20 17.134 20 21V22H4V21Z"
              />
            </svg>
            <span className="font-medium">Profile</span>
          </Link>
          <div className="px-4 py-3 rounded-lg bg-accent/5 text-sm text-text/80">
            <p className="font-medium">Need help?</p>
            <p>Check our documentation</p>
          </div>
        </div>
      </div>

      {/* Overlay for mobile menu */}
      {isMobileMenuOpen && (
        <div
          className="fixed inset-0 z-20 bg-black/60 backdrop-blur-sm transition-opacity duration-300 lg:hidden"
          onClick={toggleMobileMenu}
        ></div>
      )}
    </div>
  );
};

export default Sidebar;