import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';

const SwapExchange = () => {
  const [fromCurrency, setFromCurrency] = useState('');
  const [toCurrency, setToCurrency] = useState('');
  const [inputAmount, setInputAmount] = useState('');
  const [address, setAddress] = useState('');
  const [exchangeId, setExchangeId] = useState('');
  const [quote, setQuote] = useState(null);
  const [transaction, setTransaction] = useState(null);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [isFetchingPairs, setIsFetchingPairs] = useState(false);
  const [timer, setTimer] = useState(null);
  const [status, setStatus] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [timeLeft, setTimeLeft] = useState(null);
  const [error, setError] = useState(null);

  const fetchPairs = async () => {
    if (currencyOptions.length === 0 && !isFetchingPairs) {
      setIsFetchingPairs(true);
      try {
        const response = await axios.get('https://cryptools.info/api/get-pairs');
        if (response.data) {
          setCurrencyOptions(
            response.data.map((pair) => ({
              value: pair.value,
              label: (
                <div className="flex items-center">
                  <img 
                    src={pair.icon} 
                    alt={pair.value} 
                    className="w-5 h-5 mr-2" 
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.style.display = 'none';
                    }}
                    style={{ display: 'inline' }}
                  />
                  {pair.label || pair.value.toUpperCase()}
                </div>
              ),
            }))
          );
          setError(null);
        }      } catch (err) {
        setError('Failed to fetch currency pairs. Please try again.');
        console.error('Failed to fetch currency pairs:', err);
      } finally {
        setIsFetchingPairs(false);
      }
    }
  };
  const handleGetQuote = async () => {
    try {
      const response = await axios.post('https://cryptools.info/api/get-quote', {
        fromCurrency,
        toCurrency,
        amount: inputAmount,
      });
      setQuote(response.data.estimatedAmount);
      setError(null);
    } catch (err) {
      const errorMessage = err.response?.data?.message || 'Failed to get quote. Please try again.';
      setError(errorMessage);
      setQuote(null);
    }
  };


  const handleCreateExchange = async () => {
    try {
      const response = await axios.post('https://cryptools.info/api/create-exchange', {
        fromCurrency,
        toCurrency,
        amount: inputAmount,
        address,
      });
  
      console.log('Exchange Response:', response.data);
      setTransaction({
        id: response.data.id,
        depositAddress: response.data.depositAddress,
        receivingAddress: response.data.receivingAddress,
        expectedAmount: response.data.expectedAmount,
        extraId: response.data.extraId,
        extraIdValue: response.data.extraIdValue,
      });
  
      if (response.data.validUntil) {
        const apiExpiry = parseInt(response.data.validUntil) * 1000;
        setTimer(apiExpiry);
        setTimeLeft(apiExpiry - Date.now());
      }
  
      setIsPopupOpen(true);
      checkStatusPeriodically(response.data.id);
    } catch (error) {
      console.error('Failed to create exchange:', error);
      // Display the error message from the backend
      setError(error.response?.data?.error || 'Failed to create exchange. Please try again.');
    }
  };
  


  const handleFetchExchange = async () => {
    try {
      const response = await axios.get(`https://cryptools.info/api/check-exchange-status/${exchangeId}`);
      
      setTransaction({
        id: exchangeId,
        depositAddress: response.data.address_from,
        receivingAddress: response.data.address_to,
        expectedAmount: response.data.amount_to,
        extraId: response.data.extraId,
        extraIdValue: response.data.extraIdValue,
      });
  
      if (response.data.valid_until) {
        const expiryTime = new Date(response.data.valid_until).getTime();
        setTimer(expiryTime);
        setTimeLeft(expiryTime - Date.now());
      }
  
      setStatus(response.data.status);
      setIsPopupOpen(true);
      checkStatusPeriodically(exchangeId);
    } catch (error) {
      console.error('Failed to fetch exchange:', error);
    }
  };


  const checkStatusPeriodically = (exchangeId) => {
    const intervalId = setInterval(async () => {
      try {
        const response = await axios.get(`https://cryptools.info/api/check-exchange-status/${exchangeId}`);
        const currentStatus = response.data.status;
  
        if (response.data.valid_until) {
          const expiryTime = new Date(response.data.valid_until).getTime();
          const currentTimeLeft = expiryTime - Date.now();
  
          if (currentTimeLeft > 0 && !['expired', 'failed', 'finished', 'refunded'].includes(currentStatus)) {
            setTimer(expiryTime);
            setTimeLeft(currentTimeLeft);
          } else {
            setTimeLeft(0);
          }
        }

        if (currentStatus !== status) {
          setStatus(currentStatus);
          console.log(`Status update for Exchange ID ${exchangeId}: ${currentStatus}`);

          switch (currentStatus) {
            case 'confirming':
            case 'exchanging':
            case 'sending':
            case 'verifying':
            case 'waiting':
              break;
            case 'expired':
            case 'failed':
            case 'finished':
            case 'refunded':
              setTimeLeft(0);
              clearInterval(intervalId);
              break;
            default:
              console.warn(`Unknown status received: ${currentStatus}`);
          }
        }
      } catch (error) {
        console.error('Failed to check exchange status:', error.message);
        clearInterval(intervalId);
      }
    }, 5000);

    return () => clearInterval(intervalId);
  };

      
  useEffect(() => {
    let countdown;
    if (timer) {
      countdown = setInterval(() => {
        const timeRemaining = Math.max(0, timer - Date.now()); // Ensure timeRemaining is at least 0
        setTimeLeft(timeRemaining);
        if (timeRemaining <= 0) {
          clearInterval(countdown);
        }
      }, 1000);
    }
    return () => clearInterval(countdown);
  }, [timer]);
  

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-[#121212] text-[#e0e0e0]">
      <div className="p-8 bg-[#1f1f1f] rounded-2xl shadow-lg border border-[#bb86fc]/20 w-[32rem]">
        <h1 className="text-3xl font-bold mb-6 text-center text-[#bb86fc]">Swap Exchange</h1>
        {error && (
          <div className="bg-red-500/10 border border-red-500 text-red-500 p-4 rounded-lg mb-4 whitespace-pre-line">
            <p>{error}</p>
          </div>
        )}
        <div className="mb-4">
          <label className="block text-[#e0e0e0]/70 mb-2">From</label>
          <Select
            options={currencyOptions}
            onChange={(selectedOption) => setFromCurrency(selectedOption.value)}
            onMenuOpen={fetchPairs}
            isLoading={isFetchingPairs}
            placeholder="Select currency"
            className="text-black"
          />
        </div>

        <div className="mb-4">
          <label className="block text-[#e0e0e0]/70 mb-2">To</label>
          <Select
            options={currencyOptions}
            onChange={(selectedOption) => setToCurrency(selectedOption.value)}
            onMenuOpen={fetchPairs}
            isLoading={isFetchingPairs}
            placeholder="Select currency"
            className="text-black"
          />
        </div>

        <div className="mb-4">
          <label className="block text-[#e0e0e0]/70 mb-2">Amount</label>
          <input
            type="number"
            value={inputAmount}
            onChange={(e) => setInputAmount(e.target.value)}
            className="w-full p-3 bg-[#333333] border border-[#bb86fc]/20 rounded-lg"
            placeholder="Enter amount"
          />
        </div>

        <div className="mb-4">
          <label className="block text-[#e0e0e0]/70 mb-2">Recipient Address</label>
          <input
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            className="w-full p-3 bg-[#333333] border border-[#bb86fc]/20 rounded-lg"
            placeholder="Enter your recipient wallet address"
          />
        </div>

        <button
          onClick={quote ? handleCreateExchange : handleGetQuote}
          className={`w-full p-3 rounded-lg mb-4 ${quote ? (transaction ? 'bg-red-500' : 'bg-green-500') : 'bg-[#6a0dad]'} text-white relative group`}
          title={quote && transaction ? "Click to view exchange information" : ""}
        >
          {quote ? (transaction ? 'Swap Initiated' : 'Close Deal') : 'Fetch Quote'}
          {quote && transaction && (
            <span className="absolute invisible group-hover:visible bg-gray-900 text-white text-sm rounded py-1 px-2 -top-10 left-1/2 transform -translate-x-1/2 whitespace-nowrap">
              Click to view exchange information
            </span>
          )}
        </button>

        {transaction && (
          <button
            onClick={() => window.location.reload()}
            className="w-full p-3 rounded-lg mb-4 bg-[#6a0dad] text-white"
          >
            New Swap
          </button>
        )}

        {quote && (
          <div className="bg-[#2d2d2d] p-4 rounded-lg border border-green-500/20 shadow-lg backdrop-blur-sm">
            <div className="flex items-center space-x-2">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
              </svg>
              <p className="text-green-400 font-semibold">Estimated Output:</p>
              <span className="text-green-500 font-bold text-lg">{quote} {toCurrency.toUpperCase()}</span>
            </div>
          </div>
        )}

        <div className="my-8 border-t border-[#bb86fc]/20"></div>
        <div className="mb-4">
          <label className="block text-[#e0e0e0]/70 mb-2 relative group cursor-help">
            Existing Exchange ID
            <span className="absolute invisible group-hover:visible bg-[#2d2d2d] text-white text-sm rounded-lg py-2 px-3 -top-12 left-0 transform w-64 shadow-lg border border-[#bb86fc]/20 backdrop-blur-sm transition-all duration-200 z-10">
              Enter your exchange ID here to check or open existing exchanges
              <span className="absolute -bottom-1 left-4 w-2 h-2 bg-[#2d2d2d] border-r border-b border-[#bb86fc]/20 transform rotate-45"></span>
            </span>
          </label>
          <input
            type="text"
            value={exchangeId}
            onChange={(e) => setExchangeId(e.target.value)}
            className="w-full p-3 bg-[#333333] border border-[#bb86fc]/20 rounded-lg"
            placeholder="Enter exchange ID to fetch"
          />
        </div>

        <button
          onClick={handleFetchExchange}
          className="w-full bg-[#8b00ff] text-white p-3 rounded-lg mb-4"
        >
          Open Existing Exchange
        </button>

        {transaction && (
          <button
            onClick={() => setIsPopupOpen(true)}
            className="fixed bottom-8 right-8 w-14 h-14 rounded-full bg-[#bb86fc] text-white shadow-lg hover:bg-[#9965d4] transition-colors duration-200 flex items-center justify-center"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </button>
        )}
        {/* MODAL */}

        {isPopupOpen && transaction && (
          <>
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
              <div className="p-8 bg-[#1f1f1f] rounded-2xl shadow-lg border border-[#bb86fc]/20 w-[42rem] max-w-[95vw] relative mx-4 md:mx-0 md:transform md:translate-x-[141px] md:translate-y-[4px]">
                <button
                  onClick={() => setIsPopupOpen(false)}
                  className="absolute top-4 right-4 text-gray-400 hover:text-white"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>

                <div className="space-y-4">
                  <h2 className="text-2xl font-bold text-[#bb86fc] mb-4">Transaction Details</h2>
                  <div className="bg-[#2d2d2d] p-6 rounded-lg">
                    <div className="relative text-[#e0e0e0] mb-3 group">
                      <div className="flex items-center justify-between p-3 bg-[#333333] rounded-lg hover:bg-[#3d3d3d] transition-all duration-200">
                        <div className="break-all pr-2">
                          <span className="font-semibold">Exchange ID:</span>
                          <p className="font-mono">{transaction.id}</p>
                        </div>
                        <button 
                          onClick={() => {
                            navigator.clipboard.writeText(transaction.id);
                            const notification = document.createElement('div');
                            notification.className = 'fixed bottom-4 right-4 bg-[#bb86fc] text-white px-4 py-2 rounded-lg shadow-lg z-50 animate-fade-in-out';
                            notification.textContent = 'Exchange ID copied!';
                            document.body.appendChild(notification);
                            setTimeout(() => notification.remove(), 1250);
                          }}
                          className="p-2 hover:bg-[#444444] rounded-lg transition-colors duration-200 flex-shrink-0"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
                          </svg>
                        </button>
                      </div>
                    </div>

                    <div className="relative text-[#e0e0e0] mb-3 group">
                      <div className="flex items-center justify-between p-3 bg-[#333333] rounded-lg hover:bg-[#3d3d3d] transition-all duration-200">
                        <div className="break-all pr-2">
                          <span className="font-semibold">Send to:</span>
                          <p className="font-mono">{transaction.depositAddress}</p>
                        </div>
                        <button 
                          onClick={() => {
                            navigator.clipboard.writeText(transaction.depositAddress);
                            const notification = document.createElement('div');
                            notification.className = 'fixed bottom-4 right-4 bg-[#bb86fc] text-white px-4 py-2 rounded-lg shadow-lg z-50 animate-fade-in-out';
                            notification.textContent = 'Deposit Address copied!';
                            document.body.appendChild(notification);
                            setTimeout(() => notification.remove(), 1250);
                          }}
                          className="p-2 hover:bg-[#444444] rounded-lg transition-colors duration-200 flex-shrink-0"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
                          </svg>
                        </button>
                      </div>
                    </div>

                    <div className="relative text-[#e0e0e0] mb-3 group">
                      <div className="flex items-center justify-between p-3 bg-[#333333] rounded-lg hover:bg-[#3d3d3d] transition-all duration-200">
                        <div className="break-all pr-2">
                          <span className="font-semibold">Receiving at:</span>
                          <p className="font-mono">{transaction.receivingAddress}</p>
                        </div>
                        <button 
                          onClick={() => {
                            navigator.clipboard.writeText(transaction.receivingAddress);
                            const notification = document.createElement('div');
                            notification.className = 'fixed bottom-4 right-4 bg-[#bb86fc] text-white px-4 py-2 rounded-lg shadow-lg z-50 animate-fade-in-out';
                            notification.textContent = 'Receiving Address copied!';
                            document.body.appendChild(notification);
                            setTimeout(() => notification.remove(), 1250);
                          }}
                          className="p-2 hover:bg-[#444444] rounded-lg transition-colors duration-200 flex-shrink-0"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002-2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
                          </svg>
                        </button>
                      </div>
                    </div>

                    <div className="relative text-[#e0e0e0] mb-3 group">
                      <div className="flex items-center justify-between p-3 bg-[#333333] rounded-lg hover:bg-[#3d3d3d] transition-all duration-200">
                        <div className="break-all pr-2">
                          <span className="font-semibold">Send Exactly:</span>
                          <p className="font-mono">{transaction.expectedAmount} {fromCurrency.toUpperCase()}</p>
                        </div>
                        <button 
                          onClick={() => {
                            navigator.clipboard.writeText(transaction.expectedAmount);
                            const notification = document.createElement('div');
                            notification.className = 'fixed bottom-4 right-4 bg-[#bb86fc] text-white px-4 py-2 rounded-lg shadow-lg z-50 animate-fade-in-out';
                            notification.textContent = 'Amount copied!';
                            document.body.appendChild(notification);
                            setTimeout(() => notification.remove(), 1250);
                          }}
                          className="p-2 hover:bg-[#444444] rounded-lg transition-colors duration-200 flex-shrink-0"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002-2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
                          </svg>
                        </button>
                      </div>
                    </div>

                    {transaction.extraId && (
                      <div className="relative text-[#e0e0e0] mb-3 group">
                        <div className="flex items-center justify-between p-3 bg-[#333333] rounded-lg hover:bg-[#3d3d3d] transition-all duration-200">
                          <div className="break-all pr-2">
                            <span className="font-semibold">{transaction.extraId}:</span>
                            <p className="font-mono">{transaction.extraIdValue}</p>
                            {transaction.extraIdValue !== '-' && (
                              <div className="mt-2 bg-yellow-500/10 border border-yellow-500/50 text-yellow-500 p-3 rounded-lg text-sm">
                                ⚠️ Important: You must include this {transaction.extraId} when sending funds, or they may be lost!
                              </div>
                            )}
                          </div>
                          <button 
                            onClick={() => {
                              navigator.clipboard.writeText(transaction.extraIdValue);
                              const notification = document.createElement('div');
                              notification.className = 'fixed bottom-4 right-4 bg-[#bb86fc] text-white px-4 py-2 rounded-lg shadow-lg z-50 animate-fade-in-out';
                              notification.textContent = 'Memo copied!';
                              document.body.appendChild(notification);
                              setTimeout(() => notification.remove(), 1250);
                            }}
                            className="p-2 hover:bg-[#444444] rounded-lg transition-colors duration-200 flex-shrink-0"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002-2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
                            </svg>
                          </button>
                        </div>
                      </div>
                    )}

                    <div className="relative group">
                      <p className="text-[#e0e0e0] mb-3 flex items-center gap-2">
                        <span className="font-semibold">Status:</span> 
                        <span>{status}</span>
                        <div className="flex items-center gap-1">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 text-[#bb86fc] cursor-help">
                            <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z" clipRule="evenodd" />
                          </svg>
                        </div>
                        <div className="absolute left-0 -bottom-2 transform translate-y-full opacity-0 group-hover:opacity-100 transition-all duration-300 pointer-events-none z-50">
                          <div className="bg-[#1a1a1a] text-sm text-[#e0e0e0] p-4 rounded-xl shadow-2xl border border-[#bb86fc]/20 w-64 backdrop-blur-xl">
                            <div className="absolute -top-2 left-16 w-4 h-4 bg-[#1a1a1a] border-l border-t border-[#bb86fc]/20 transform rotate-45"></div>
                            <ul className="space-y-2">
                            <li className="flex items-center gap-2">
                            <div className="w-2 h-2 rounded-full bg-[#bb86fc]"></div>new: Transaction Initialized
                            </li>
                            <li className="flex items-center gap-2">
                            <div className="w-2 h-2 rounded-full bg-yellow-500"></div>waiting: Awaiting Deposit
                            </li>
                            <li className="flex items-center gap-2">
                            <div className="w-2 h-2 rounded-full bg-green-100"></div>confirming: Deposit Confirming
                            </li>
                            <li className="flex items-center gap-2">
                            <div className="w-2 h-2 rounded-full bg-green-300"></div>exchanging: Funds Exchanging
                            </li>
                            <li className="flex items-center gap-2">
                            <div className="w-2 h-2 rounded-full bg-blue-500"></div>sending: Sending to Recipient
                            </li>
                            <li className="flex items-center gap-2">
                            <div className="w-2 h-2 rounded-full bg-green-500"></div>finished: Swap Completed
                            </li>
                            <li className="flex items-center gap-2">
                            <div className="w-2 h-2 rounded-full bg-red-500"></div>failed: Swap Failed
                            </li>
                            <li className="flex items-center gap-2">
                            <div className="w-2 h-2 rounded-full bg-gray-500"></div>refunded: Funds Refunded
                            </li>

                            </ul>
                          </div>
                        </div>
                      </p>
                    </div>


                    <p className="text-[#e0e0e0] mb-3">
                      <span className="font-semibold">Time Remaining:</span> {Math.floor(timeLeft / 60000)}m {Math.floor((timeLeft % 60000) / 1000)}s
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SwapExchange;
