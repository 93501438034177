// src/BalanceChecker.jsx
import React, { useState } from 'react';
import axios from 'axios';
import Select, { components } from 'react-select';

// Wallet Options with icons
const walletOptions = [
  { value: 'eth', label: 'Ethereum', icon: require('./public/blockchain_Icons/ethereum.png') },
  { value: 'solana', label: 'Solana', icon: require('./public/blockchain_Icons/sol.png') },
  { value: 'btc', label: 'Bitcoin', icon: require('./public/blockchain_Icons/Bitcoin.png') },
  { value: 'tron', label: 'TRON', icon: require('./public/blockchain_Icons/tron.png') },
  { value: 'ton', label: 'TON', icon: require('./public/blockchain_Icons/ton.png') },
  { value: 'bsc', label: 'Binance Smart Chain', icon: require('./public/blockchain_Icons/bsc.png') },
  { value: 'optimism', label: 'Optimism', icon: require('./public/blockchain_Icons/op.png') },
  { value: 'polygon', label: 'Polygon', icon: require('./public/blockchain_Icons/matic.png') },
  { value: 'avalanche', label: 'Avalanche C-Chain', icon: require('./public/blockchain_Icons/avax.png') },
  { value: 'arbitrum', label: 'Arbitrum', icon: require('./public/blockchain_Icons/arb.png') },
  { value: 'fantom', label: 'Fantom', icon: require('./public/blockchain_Icons/fantom.png') },
  { value: 'base', label: 'Base', icon: require('./public/blockchain_Icons/base.png') },
  { value: 'blast', label: 'Blast', icon: require('./public/blockchain_Icons/blast.png') },
  { value: 'linea', label: 'Linea', icon: require('./public/blockchain_Icons/linea.png') },
  { value: 'taiko', label: 'Taiko', icon: require('./public/blockchain_Icons/taiko.png') },
  { value: 'cronos', label: 'Cronos', icon: require('./public/blockchain_Icons/cronos.png') },
  { value: 'gnosis', label: 'Gnosis', icon: require('./public/blockchain_Icons/gnosis.png') },
  { value: 'celo', label: 'Celo', icon: require('./public/blockchain_Icons/celo.png') },
  { value: 'moonbeam', label: 'Moonbeam', icon: require('./public/blockchain_Icons/moonbeam.png') },
  //TO DO
  // { value: 'xrp', label: 'XRP', icon: '/xrp.png' },
  // { value: 'polkadot', label: 'Polkadot', icon: '/polkadot.png' },
  // { value: 'tezos', label: 'Tezos', icon: '/tezos.png' },
  // { value: 'ripple', label: 'Ripple', icon: '/ripple.png' },
  // { value: 'near', label: 'NEAR', icon: '/near.png' },

];

const Option = (props) => (
  <components.Option {...props}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img src={props.data.icon} alt={props.data.label} style={{ width: '20px', height: '20px', marginRight: '10px' }} />
      <span>{props.data.label}</span>
    </div>
  </components.Option>
);
const BalanceChecker = () => {
  const [input, setInput] = useState('');
  const [balances, setBalances] = useState([]);
  const [isChecking, setIsChecking] = useState(false);
  const [error, setError] = useState('');
  const [selectedChain, setSelectedChain] = useState(walletOptions[0]); // Default to Ethereum
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsChecking(true);
    setBalances([]);
    setError('');

    // Add input validation
    if (selectedChain.value === 'solana' && input.length < 64) {
      setError('Solana input must be a valid private key');
      setIsChecking(false);
      return;
    }

    try {
      const response = await axios.post('https://cryptools.info/api/check-balance', 
        { input, selectedChain: selectedChain.value }
      );
      setBalances(Array.isArray(response.data.balance) ? response.data.balance : [response.data.balance]);
    } catch (error) {
      setError(error.response?.data?.error || 'Failed to fetch balances');
    } finally {
      setIsChecking(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-[#121212] text-[#e0e0e0]">
      <div className="p-8 bg-[#1f1f1f] rounded-2xl shadow-lg border border-[#bb86fc]/20 w-[32rem]">
        <h1 className="text-3xl font-bold mb-6 text-center text-[#bb86fc]">Balance Checker Tool</h1>
        <p className="mb-6 text-[#e0e0e0]/70 text-center">Select your blockchain, and enter a valid private key to check the wallet balances:</p>

        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Enter a valid private key or seed (no mnemonics)"
            className="w-full p-3 bg-[#333333] border border-[#bb86fc]/20 rounded-lg text-[#e0e0e0]"
            required
          />
          <Select
            options={walletOptions}
            value={selectedChain}
            onChange={setSelectedChain}
            components={{ Option }}
            className="text-[#e0e0e0]"
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#333333',
                primary: '#bb86fc',
                neutral0: '#1f1f1f',
                neutral80: '#e0e0e0',
              },
            })}
          />
          <button
            type="submit"
            className="w-full p-3 rounded-lg bg-[#6a0dad] text-white hover:bg-[#6a0dad]/80"
            disabled={isChecking}
          >
            {isChecking ? 'Checking...' : 'Check Balances'}
          </button>
        </form>

        {error && (
          <div className="mt-4 text-red-400 text-center">
            <p>{error}</p>
          </div>
        )}

        {balances.length > 0 && (
          <div className="bg-[#2d2d2d] p-6 rounded-lg border border-[#bb86fc]/20 shadow-lg backdrop-blur-sm mt-4">
            <h3 className="text-lg font-semibold text-[#bb86fc] mb-4">Wallet Balances:</h3>
            <div className="max-h-60 overflow-y-auto space-y-2">
              {balances.map((balanceInfo, index) => (
                <div key={index} className="p-4 bg-[#333333] rounded-lg break-all border border-[#bb86fc]/20">
                  <div className="grid gap-2">
                    <div>
                      <span className="text-[#e0e0e0]/70">Chain: </span>
                      <span className="text-[#e0e0e0]">{String(balanceInfo.chain).toUpperCase()}</span>
                    </div>
                    <div>
                      <span className="text-[#e0e0e0]/70">Address: </span>
                      <span className="text-[#e0e0e0] font-mono">{String(balanceInfo.address)}</span>
                      <button 
                        className="ml-2 text-[#bb86fc] hover:text-[#bb86fc]/80" 
                        onClick={() => navigator.clipboard.writeText(String(balanceInfo.address))}
                      >
                        Copy
                      </button>
                    </div>
                    <div>
                      <span className="text-[#e0e0e0]/70">Private Key: </span>
                      <span className="text-[#e0e0e0] font-mono">{String(balanceInfo.key)}</span>
                      <button 
                        className="ml-2 text-[#bb86fc] hover:text-[#bb86fc]/80" 
                        onClick={() => navigator.clipboard.writeText(String(balanceInfo.key))}
                      >
                        Copy
                      </button>
                    </div>
                    <div>
                      <span className="text-[#e0e0e0]/70">Balance: </span>
                      <span className="text-[#e0e0e0]">{String(balanceInfo.balance)}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {!isChecking && balances.length === 0 && (
          <div className="mt-4 text-[#e0e0e0]/50 text-center">
            <p>No balances found for the entered information.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default BalanceChecker;