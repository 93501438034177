import React, { useState } from 'react';
import Select, { components } from 'react-select';

function MultipleWalletGenerator() {
  const [walletType, setWalletType] = useState('btc');
  const [quantity, setQuantity] = useState(1);
  const [wallets, setWallets] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [progress, setProgress] = useState(0);

  // Disable all console methods
  console.log = function () {};
  console.warn = function () {};
  console.error = function () {};
  console.info = function () {};
  console.debug = function () {};

  const walletOptions = [
    { value: 'btc', label: 'Bitcoin', icon: require('./public/blockchain_Icons/Bitcoin.png') },
    { value: 'eth', label: 'ETH (EVM)', icon: require('./public/blockchain_Icons/ethereum.png')},
    { value: 'tron', label: 'TRON', icon: require('./public/blockchain_Icons/tron.png') },
    { value: 'sol', label: 'Solana', icon: require('./public/blockchain_Icons/sol.png') },
    { value: 'ton', label: 'TON (The Open Network)', icon: require('./public/blockchain_Icons/ton.png') },
    { value: 'polkadot', label: 'Polkadot', icon: require('./public/blockchain_Icons/polkadot.png') },
    { value: 'ripple', label: 'Ripple', icon: require('./public/blockchain_Icons/ripple.png') },
    { value: 'tezos', label: 'Tezos', icon: require('./public/blockchain_Icons/tezos.png') },
    { value: 'near', label: 'NEAR Protocol', icon: require('./public/blockchain_Icons/near.png') },
    { value: 'xrp', label: 'XRP', icon: require('./public/blockchain_Icons/xrp.png') }
  ];
  const Option = (props) => {
    return (
      <components.Option {...props}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={props.data.icon}
            alt={props.data.label}
            style={{ width: '20px', height: '20px', marginRight: '10px' }}
          />
          <span>{props.data.label}</span>
        </div>
      </components.Option>
    );
  };

  const SingleValue = (props) => (
    <components.SingleValue {...props}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img
          src={props.data.icon}
          alt={props.data.label}
          style={{ width: '20px', height: '20px', marginRight: '10px' }}
        />
        <span>{props.data.label}</span>
      </div>
    </components.SingleValue>
  );

  const generateWallets = async () => {
    setIsGenerating(true);
    setWallets([]);
    setProgress(0);

    try {
      setProgress(10);

      const response = await fetch(`https://cryptools.info/api/generate-multiple-wallets/${walletType}/${quantity}`);

      if (!response.ok) {
        throw new Error(`Error generating wallets`);
      }

      setProgress(50);

      const data = await response.json();
      setWallets(data.data);

      setProgress(100);
      setIsGenerating(false);
    } catch (error) {
      console.error('Wallet generation failed:', error);
      setIsGenerating(false);
    }
  };
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-[#121212] text-[#e0e0e0]">
      <div className="p-8 bg-[#1f1f1f] rounded-2xl shadow-lg border border-[#bb86fc]/20 w-[32rem]">
        <h1 className="text-3xl font-bold mb-6 text-center text-[#bb86fc]">Multiple Wallet Generation</h1>

        <div className="mb-4">
          <Select
            components={{ Option, SingleValue }}
            options={walletOptions}
            onChange={(selectedOption) => setWalletType(selectedOption.value)}
            defaultValue={walletOptions.find(option => option.value === 'btc')}
            className="text-[#e0e0e0]"
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#333333',
                primary: '#bb86fc',
                neutral0: '#1f1f1f',
                neutral80: '#e0e0e0',
              },
            })}
          />
        </div>

        <div className="mb-4">
          <input
            type="number"
            min="1"
            max="1000"
            className="w-full p-3 bg-[#333333] border border-[#bb86fc]/20 rounded-lg text-[#e0e0e0]"
            placeholder="Enter quantity"
            value={quantity}
            onChange={(e) => setQuantity(Number(e.target.value))}
          />
        </div>

        <button
          className="w-full p-3 rounded-lg mb-4 bg-[#6a0dad] text-white"
          onClick={generateWallets}
          disabled={isGenerating}
        >
          {isGenerating ? 'Generating...' : 'Generate Wallets'}
        </button>

        {isGenerating && (
          <div className="text-center text-[#bb86fc] font-medium mb-4">
            {Math.min(progress, 100).toFixed(1)}%
          </div>
        )}

        {wallets.length > 0 && (
          <div className="bg-[#2d2d2d] p-6 rounded-lg border border-[#bb86fc]/20 shadow-lg backdrop-blur-sm">
            <h3 className="text-lg font-semibold text-[#bb86fc] mb-4">Generated Wallets:</h3>
            
            <button
              className="w-full p-3 rounded-lg mb-4 bg-[#6a0dad] text-white"
              onClick={() => exportToCSV(wallets)}
            >
              Download CSV
            </button>

            <div className="max-h-60 overflow-y-auto space-y-2">
              {wallets.map((wallet, index) => (
                <div key={index} className="p-4 bg-[#333333] rounded-lg break-all border border-[#bb86fc]/20">
                  <div className="text-[#e0e0e0]/70 mb-1">Address:</div>
                  <div className="font-mono mb-3 text-[#e0e0e0]">{wallet.address}</div>
                  <div className="text-[#e0e0e0]/70 mb-1">
                    {walletType === 'polkadot' ? 'Mnemonic' : 'Private Key'}:
                  </div>
                  <div className="font-mono text-[#e0e0e0]">{wallet.privateKey}</div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
// Helper function to export wallets as CSV
function exportToCSV(wallets) {
  const csvContent =
    'data:text/csv;charset=utf-8,' +
    'Address,PrivateKey\n' + // Add headers
    wallets.map((w) => `${w.address},${w.privateKey}`).join('\n');
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', 'wallets.csv');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export default MultipleWalletGenerator;
