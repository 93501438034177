import React, { useState } from 'react';
import Select, { components } from 'react-select';

// Disable all console methods
console.log = function () {};
console.warn = function () {};
console.error = function () {};
console.info = function () {};
console.debug = function () {};

function WalletGenerator() {
  const [walletType, setWalletType] = useState('btc');
  const [wallet, setWallet] = useState(null);
  const walletOptions = [
    { value: 'btc', label: 'Bitcoin', icon: require('./public/blockchain_Icons/Bitcoin.png') },
    { value: 'eth', label: 'ETH (EVM)', icon: require('./public/blockchain_Icons/ethereum.png')},
    { value: 'tron', label: 'TRON', icon: require('./public/blockchain_Icons/tron.png') },
    { value: 'sol', label: 'Solana', icon: require('./public/blockchain_Icons/sol.png') },
    { value: 'ton', label: 'TON (The Open Network)', icon: require('./public/blockchain_Icons/ton.png') },
    { value: 'polkadot', label: 'Polkadot', icon: require('./public/blockchain_Icons/polkadot.png') },
    { value: 'ripple', label: 'Ripple', icon: require('./public/blockchain_Icons/ripple.png') },
    { value: 'tezos', label: 'Tezos', icon: require('./public/blockchain_Icons/tezos.png') },
    { value: 'near', label: 'NEAR Protocol', icon: require('./public/blockchain_Icons/near.png') },
    { value: 'xrp', label: 'XRP', icon: require('./public/blockchain_Icons/xrp.png') }
];
  const Option = (props) => {    return (
      <components.Option {...props}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={props.data.icon}
            alt={props.data.label}
            style={{ width: '20px', height: '20px', marginRight: '10px' }}
          />
          <span>{props.data.label}</span>
        </div>
      </components.Option>
    );
  };

  const SingleValue = (props) => (
    <components.SingleValue {...props}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img
          src={props.data.icon}
          alt={props.data.label}
          style={{ width: '20px', height: '20px', marginRight: '10px' }}
        />
        <span>{props.data.label}</span>
      </div>
    </components.SingleValue>
  );

  const generateWallet = async () => {
    try {
      const response = await fetch(`https://cryptools.info/api/generate-wallet/${walletType}`);

      if (!response.ok) {
        throw new Error(`Server error: ${response.status}`);
      }

      const data = await response.json();
      setWallet(data.data);
    } catch (error) {
      console.error('Wallet generation failed:', error);
    }
  };
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-[#121212] text-[#e0e0e0]">
      <div className="p-8 bg-[#1f1f1f] rounded-2xl shadow-lg border border-[#bb86fc]/20 w-[32rem]">
        <h1 className="text-3xl font-bold mb-6 text-center text-[#bb86fc]">Single Wallet Generation</h1>
        {/* Custom Dropdown */}
        <Select
          components={{ Option, SingleValue }}
          options={walletOptions}
          onChange={(selectedOption) => setWalletType(selectedOption.value)}
          defaultValue={walletOptions.find((option) => option.value === 'btc')}
          className="w-full p-3 rounded-lg mb-4 bg-[#bb86fc] text-white"
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: '#333333',
              primary: '#bb86fc',
              neutral0: '#1f1f1f',
              neutral80: '#e0e0e0',
            },
          })}
        />

        {/* Generate Button */}
        <button
          className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded w-full"
          onClick={generateWallet}
        >
          Generate Wallet
        </button>

        {/* Display Wallet Details */}
        {wallet && (
          <div className="p-4 bg-[#333333] rounded-lg break-all border border-[#bb86fc]/20">
            <div className="text-[#e0e0e0]/70 mb-1">Address:</div>
            <div className="font-mono mb-3 text-[#e0e0e0]">{wallet.address}</div>
            <div className="text-[#e0e0e0]/70 mb-1">
              {walletType === 'polkadot' ? 'Mnemonic' : 'Private Key'}:
            </div>
            <div className="font-mono text-[#e0e0e0]">{wallet.privateKey}</div>
          </div>
        )}
      </div>
    </div>  );
}

export default WalletGenerator;
