import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { WalletConnectModal } from "@walletconnect/modal";
import { ethers } from "ethers";

const WalletModal = ({ isOpen, onClose, onConnectEVM, onConnectTron, onConnectSolana, isConnected, onDisconnect }) => {
  const [walletProvider, setWalletProvider] = useState(null);

  const connectWalletConnect = async () => {
    try {
      // Initialize WalletConnect Modal
      const modal = new WalletConnectModal({
        projectId: "421bf490b6ea06fbeb79f8fc984b9211", // Replace with your WalletConnect project ID
        themeMode: "dark",
        explorerRecommendedWalletIds: [
          "1ae92b26df02f0abca6304df07debccd18262fdf5fe82daa81593582dac9a369",
          "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0",
          "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
          "a797aa35c0fadbfc1a53e7f675162ed5226968b44a19ee3d24385c64d1d3c393"
        ],
        qrcodeModalOptions: {
          mobileLinks: ["metamask", "trust", "rainbow", "argent", "imtoken", "pillar"],
          desktopLinks: ["metamask", "ledger", "rainbow", "argent"],
        },
        standaloneChains: ["eip155:1"]
      });
      
      // Opens the WalletConnect modal for the user to choose a wallet
      const provider = await modal.openModal();
      
      if (!provider) {
        throw new Error("Provider not found after opening modal");
      }

      setWalletProvider(provider);

      const ethersProvider = new ethers.BrowserProvider(provider);
      const signer = ethersProvider.getSigner();
      const address = await signer.getAddress();
      console.log("Connected WalletConnect Address:", address);

      onClose();
    } catch (error) {
      console.error("Error connecting with WalletConnect:", error);
    }
  };


  

  const walletOptions = [
    { name: 'EVM', onClick: onConnectEVM, bgColor: 'bg-gradient-to-r from-orange-400 to-yellow-400' },
    {
      name: 'TRON',
      onClick: () => {
        if (window.tronWeb && window.tronWeb.ready) {
          onConnectTron();
        } else {
          alert('Please check your wallet!');
        }
      },
      bgColor: 'bg-gradient-to-r from-red-500 to-pink-500',
    },
    { name: 'SOLANA', onClick: onConnectSolana, bgColor: 'bg-gradient-to-r from-purple-500 to-indigo-500' },
    { name: 'WalletConnect', onClick: connectWalletConnect, bgColor: 'bg-gradient-to-r from-blue-500 to-teal-500' },
  ];



  const handleWalletClick = (walletCallback) => {
    if (isConnected) {
      onDisconnect();
    } else {
      walletCallback();
    }
    onClose();
  };

  const handleDisconnect = async () => {
    if (walletProvider) {
      await walletProvider.disconnect();
      setWalletProvider(null);
    }
    if (window.ethereum) {
      try {
        await window.ethereum.request({
          method: "wallet_requestPermissions",
          params: [{ eth_accounts: {} }],
        });
      } catch (error) {
        console.error("Error disconnecting wallet:", error);
      }
    }
    if (window.tronWeb) {
      window.tronWeb.ready = false;
    }
    if (window.solana) {
      await window.solana.disconnect();
    }
    onDisconnect();
    onClose();
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50"
          onClick={onClose}
        >
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            transition={{ type: "spring", duration: 0.5 }}
            className="bg-white/90 dark:bg-gray-800/90 rounded-2xl p-8 max-w-sm w-full mx-4 backdrop-blur-xl shadow-2xl"
            onClick={e => e.stopPropagation()}
          >
            <h2 className="text-3xl font-bold mb-6 text-gray-800 dark:text-white bg-gradient-to-r from-purple-600 to-pink-600 bg-clip-text text-transparent text-center">
              {isConnected ? 'Disconnect Wallet' : 'Connect Wallet'}
            </h2>
            <div className="space-y-4">
              {isConnected ? (
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  className="w-full bg-gradient-to-r from-red-500 to-pink-500 text-white font-semibold py-4 px-6 rounded-xl 
                    transition-all duration-200 shadow-lg hover:shadow-xl
                    flex items-center justify-center space-x-3"
                  onClick={handleDisconnect}
                >
                  <span className="text-lg">Disconnect</span>
                </motion.button>
              ) : (
                walletOptions.map((wallet) => (
                  <motion.button
                    key={wallet.name}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    className={`w-full ${wallet.bgColor} text-white font-semibold py-4 px-6 rounded-xl 
                      transition-all duration-200 shadow-lg hover:shadow-xl
                      flex items-center justify-center space-x-3`}
                    onClick={() => handleWalletClick(wallet.onClick)}
                  >
                    <span className="text-lg">{wallet.name}</span>
                  </motion.button>
                ))
              )}
            </div>
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={onClose}
              className="mt-6 w-full bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200 
                font-semibold py-3 px-4 rounded-xl hover:bg-gray-200 dark:hover:bg-gray-600 
                transition-colors duration-200"
            >
              Cancel
            </motion.button>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default WalletModal;