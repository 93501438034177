// src/components/Home.jsx
import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div className="h-screen flex flex-col items-center justify-center  from-background to-secondary p-4">
      <h1 className="text-4xl font-bold text-accent bg-clip-text text-transparent bg-gradient-to-r from-accent to-primary">
        Welcome!
      </h1>
      <p className="text-lg text-text mt-4 mb-8 text-center">
        Feel free to take a look around!
      </p>
      <Link
        to="/crypt"
        className="bg-accent text-white font-semibold py-3 px-6 rounded-lg hover:bg-primary transition-all duration-200 shadow-lg"
      >
        Get Started
      </Link>
    </div>
  );
};

export default Home;
