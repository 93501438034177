// MnemonicDerivator.jsx
import React, { useState } from "react";
import axios from "axios";

const MnemonicDerivator = () => {
  const [mnemonic, setMnemonic] = useState("");
  const [error, setError] = useState("");
  const [results, setResults] = useState({});
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    setMnemonic(e.target.value);
    setError("");
  };

  const handleCheckBalances = async () => {
    setLoading(true);
    setResults({});
    setError("");

    try {
      const response = await axios.post("https://cryptools.info/api/check-mnemonic-derivates", { mnemonic });
      console.log("Response from backend:", response.data);
      if (response.data.success) {
        setResults(response.data.results);
      } else {
        setError(response.data.message || "Failed to retrieve balances.");
      }
    } catch (error) {
      console.error("Error during balance check:", error);
      setError("Error checking balances. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={`flex flex-col items-center justify-center min-h-screen bg-[#121212] text-[#e0e0e0] p-4 ${Object.keys(results).length > 0 ? 'min-w-screen' : ''}`}>

      <div className="h-10"></div>
      <div className={`p-8 bg-[#1f1f1f] rounded-2xl shadow-lg border border-[#bb86fc]/20 ${Object.keys(results).length > 0 ? 'w-[90%] max-w-[90%]' : 'w-full max-w-[32rem]'}`}>
        <h1 className="text-3xl font-bold mb-6 text-center text-[#bb86fc]">Mnemonic Derivates</h1>
        
        <div className="space-y-2 mb-6">
          <p className="text-[#e0e0e0]/70 text-center">
            Multi-chain auto-balanced derivates checker! (Only EVM for now)
          </p>
          <p className="text-[#e0e0e0]/70 text-center">
            Enter a valid 12 or 24-words mnemonic to check balances.
          </p>
          <p className="text-[#e0e0e0]/70 text-center">
            <span className="text-yellow-400">⚠️ Make sure to split every word with a space ⚠️</span>          </p>
        </div>

        <input
          type="text"
          value={mnemonic}
          onChange={handleInputChange}
          className="w-full p-3 bg-[#333333] border border-[#bb86fc]/20 rounded-lg text-[#e0e0e0] mb-4"
          placeholder="Enter 12 or 24-words mnemonic"
        />
        
        {error && <p className="text-red-400 mt-2">{error}</p>}
        
        <button
          onClick={handleCheckBalances}
          disabled={loading}
          className="w-full p-3 rounded-lg bg-[#6a0dad] text-white hover:bg-[#6a0dad]/80"
        >
          {loading ? "Checking...please wait and don't refresh" : "Check Balances"}
        </button>

        

        <div className="mt-8">
          {Object.keys(results).length > 0 ? (
            
            <div className="bg-[#2d2d2d] p-6 rounded-lg border border-[#bb86fc]/20 shadow-lg backdrop-blur-sm">
          
              <h3 className="text-xl font-semibold text-[#bb86fc] mb-4">Results:</h3>
              <div className="space-y-4">
                {Object.entries(results).map(([chain, balances], index) => (
                  <div key={index} className="p-4 bg-[#333333] rounded-lg border border-[#bb86fc]/20">
                    <h4 className="font-semibold text-[#bb86fc] mb-2">{chain.toUpperCase()}</h4>
                    {Array.isArray(balances) && balances.length > 0 ? (
                      <div className="space-y-3">
                        {balances.map((item, idx) => (
                          <div
                            key={idx}
                            className={`p-3 rounded-lg ${
                              item.balance > 0 ? "bg-[#2d3b2d]" : "bg-[#3b2d2d]"
                            }`}
                          >
                            <div className="grid gap-1 font-mono">
                              <p className="text-[#e0e0e0]/70 break-all">
                                Address: <span className="text-[#e0e0e0]">{item.address}</span>
                              </p>
                              <p className="text-[#e0e0e0]/70 break-all">
                                Private Key: <span className="text-[#e0e0e0]">{item.privateKey}</span>
                              </p>
                              <p className={item.balance > 0 ? "text-green-400" : "text-red-400"}>
                                Balance: {item.balance}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p className="text-red-400">No balance found</p>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            !loading && (
              <div className="text-[#e0e0e0]/50 text-center mt-4">
                <p>No balances found.</p>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default MnemonicDerivator;