// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Home from './components/Home';
// import CryptPage from './components/CryptPage';
import Profile from './components/Profile';
import SwapExchange from './components/SwapExchange';
import WalletRecovery from './components/WalletRecovery';
import WalletGenerator from './components/WalletGenerator'
import MultipleWalletGenerator from './components/MultipleWalletGenerator';
import BalanceChecker from './components/BalanceChecker';
import WalletModal from './components/WalletModal';
import MnemonicDerivator from './components/MnemonicDerivator';


function App() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [account, setAccount] = useState('');
  const [walletType, setWalletType] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const disconnectWallet = () => {
    setAccount('');
    setWalletType('');
  };

  const connectWallet = async () => {
    if (account) {
      disconnectWallet();
      return;
    }
    setIsModalOpen(true);
  };

  const connectEVM = async () => {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        setAccount(accounts[0]);
        setWalletType('EVM');
        setIsModalOpen(false);
      } catch (error) {
        console.error('Error connecting EVM wallet:', error);
      }
    } else {
      alert('Please install MetaMask!');
    }
  };

  const connectTron = async () => {
    if (window.tronWeb) {
      try {
        const account = await window.tronWeb.defaultAddress.base58;
        if (account) {
          setAccount(account);
          setWalletType('TRON');
          setIsModalOpen(false);
        } else {
          alert('Please unlock TronLink wallet!');
        }
      } catch (error) {
        console.error('Error connecting TronLink:', error);
      }
    } else {
      alert('Please install TronLink!');
    }
  };

  const connectSolana = async () => {
    if (window.solana && window.solana.isPhantom) {
      try {
        const response = await window.solana.connect();
        setAccount(response.publicKey.toString());
        setWalletType('SOLANA');
        setIsModalOpen(false);
      } catch (error) {
        console.error('Error connecting Solana wallet:', error);
      }
    } else {
      alert('Please install Phantom wallet!');
    }
  };

  return (
    <Router>
      <div className="flex flex-col min-h-screen bg-background text-text lg:flex-row">
        <div className="fixed top-4 right-4 z-50">
          <button
            onClick={connectWallet}
            className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded"
          >
            {account ? `${walletType}: ${account.slice(0, 6)}...${account.slice(-4)}` : 'Connect Wallet'}
          </button>
        </div>

        <WalletModal 
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onConnectEVM={connectEVM}
          onConnectTron={connectTron}
          onConnectSolana={connectSolana}
          isConnected={!!account}
          onDisconnect={disconnectWallet}
        />

        <Sidebar isMobileMenuOpen={isMobileMenuOpen} toggleMobileMenu={toggleMobileMenu} />
        <div className="flex-grow p-6">
          <Routes>
            <Route path="/" element={<Home />} />
            {/* <Route path="/crypt" element={<CryptPage />} /> */}
            <Route path="/profile" element={<Profile />} />
            <Route path="/SwapExchange" element={<SwapExchange />} />
            <Route path="/WalletRecovery" element={<WalletRecovery />} />
            <Route path="/WalletGenerator" element={<WalletGenerator />} />
            <Route path="/MultipleWalletGenerator" element={<MultipleWalletGenerator />} />
            <Route path="/BalanceChecker" element={<BalanceChecker />} />
            <Route path="/MnemonicDerivator" element={<MnemonicDerivator />} />

          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;